@use "./_mantine.scss" as *;
.root {
    gap: var(--mantine-spacing-xs);
    border-color: var(--mantine-color-gray-2);

    flex-direction: row;
}

.body {
    flex: 1 1 0;
    justify-content: space-between;
}

.imageWrapper {
    position: relative;

    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    aspect-ratio: 1 / 1;

    padding: 2px;

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);

    img {
        object-fit: contain;
    }
}
