@use "./_mantine.scss" as *;
.item {
    position: relative;
    cursor: default;

    display: flex;
    flex-direction: column;
    gap: var(--mantine-spacing-xs);
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;

    background-color: #ffffff;

    border: 1px solid;
    border-color: var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);

    transition: background-color 0.3s ease;

    &[data-interactive='true'] {
        cursor: move;

        touch-action: none;

        &:hover {
            background-color: var(--mantine-color-gray-0);
        }
    }
}

.hover {
    &:hover {
        box-shadow: var(--mantine-shadow-sm);
    }
}

.dragHandle {
    position: absolute;
    top: 2px;
    right: 2px;

    cursor: move;

    touch-action: none;
}
