@use "./_mantine.scss" as *;
.root {
    --inline-filters-border-radius: var(--mantine-radius-sm);

    > * {
        border-radius: 0;

        &:first-child {
            border-top-left-radius: var(--inline-filters-border-radius);
            border-bottom-left-radius: var(--inline-filters-border-radius);
            margin-right: -2px;
        }

        &:last-child {
            border-top-right-radius: var(--inline-filters-border-radius);
            border-bottom-right-radius: var(--inline-filters-border-radius);
        }
    }

    > * {
        &:first-child {
            background-color: var(--inline-filters-bg-secondary);
        }
    }

    &[data-active='true'] > * {
        outline: 1px solid var(--inline-filters-active-border-color);
    }

    order: 999;

    &[data-active='true'] {
        order: 0;

        // reset order inside port wrapper
        [data-inline-filter-section] {
            order: 999;
        }
    }

    align-items: stretch;
}
