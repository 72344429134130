@use "./_mantine.scss" as *;
.composer {
    --mantine-color-placeholder: var(--mantine-color-gray-5);

    width: 100%;

    :global .tiptap {
        min-height: 50px;

        padding: 8px 8px 4px 8px;
        font-size: 12px;

        [data-type*='mention'] {
            font-weight: 500;
            color: var(--mantine-color-primary-6);

            cursor: pointer;
        }
    }
}

.viewer {
    --mantine-color-placeholder: var(--mantine-color-gray-5);

    width: 100%;
    border: none;

    :global .mantine-RichTextEditor-content {
        background-color: transparent !important;
    }

    :global .tiptap {
        padding: 0;
        font-size: 12px;

        [data-type*='mention'] {
            font-weight: 500;
            color: var(--mantine-color-primary-6);

            cursor: pointer;
        }
    }
}

.footer {
    display: flex;
    justify-content: space-between;

    padding: 4px;
    padding-top: 0;
}

.footerSection {
    display: flex;
}

.button {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;

    background-color: transparent;
    color: var(--mantine-color-gray-7);

    border: none;
    border-radius: var(--mantine-radius-sm);

    &:not([disabled]):has(:hover, :focus) {
        background-color: var(--mantine-color-gray-1);
    }

    &[data-color='green']:not([disabled]) {
        background-color: var(--mantine-color-green-6);
        color: #ffffff;

        &:hover,
        &:focus {
            background-color: var(--mantine-color-gray-9);
        }
    }

    &:focus {
        background-color: transparent;
        outline: none;
    }

    &[disabled] {
        color: var(--mantine-color-gray-5);
    }

    svg {
        width: 16px;
        height: 16px;
    }
}

.buttonLoader {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;
}

.mentionAILabel {
    display: flex;
    align-items: center;

    margin-left: 8px;
    padding: 2px 4px;

    font-size: 10px;
    font-weight: 600;
    line-height: 1;

    background-color: var(--mantine-color-primary-1);
    color: var(--mantine-color-primary-7);

    border-radius: 2px;
}

.filesPreview {
    display: flex;
    flex-wrap: wrap;

    gap: calc(var(--mantine-spacing-xs) / 2);
    padding: 8px;

    background-color: var(--mantine-color-gray-0);
    border-top: 1px solid var(--mantine-color-gray-2);

    .thread & {
        margin-top: var(--mantine-spacing-xs);
    }

    .messageComposer & {
        padding: 4px 8px;
    }
}

.filePreview {
    position: relative;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;

    color: var(--mantine-color-gray-7) !important;
    background-color: var(--mantine-color-white);
    border: 1px solid var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-sm);

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;

        border-radius: var(--mantine-radius-sm);
    }

    svg {
        width: 40%;
        height: auto;
    }

    &:has(img) {
        background-color: transparent;
        border: none;
    }

    &:hover {
        .filePreviewDelete {
            opacity: 1;
        }
    }
}

.filePreviewDelete {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--mantine-color-white);
    background-color: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transition: opacity 0.3s ease;
}

.files {
    display: flex;
    flex-wrap: wrap;

    gap: calc(var(--mantine-spacing-xs) / 2);
    margin-top: 4px;
}

.file {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;

    color: var(--mantine-color-gray-7) !important;
    background-color: var(--mantine-color-white);
    border: 1px solid var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-sm);

    &:has(img) {
        width: auto;
    }

    img {
        width: auto;
        height: 100%;

        border-radius: var(--mantine-radius-sm);
    }

    svg {
        width: 40%;
        height: auto;
    }
}
