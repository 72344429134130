@use "./_mantine.scss" as *;
.root {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;

    width: 100%;
    height: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--brand-color);
        opacity: 0.1;
    }
}

.letter {
    font-size: 48px;
    font-weight: 700;
    color: var(--brand-color);
    line-height: 1;

    opacity: 0.5;
}
