@use "./_mantine.scss" as *;
.root {
    background-color: var(--inline-filters-bg-primary);

    --padding-x: var(--inline-filters-padding-x);
    --padding-y: var(--inline-filters-padding-y);

    padding: var(--padding-y) var(--padding-x);

    border-radius: var(--mantine-radius-sm);

    transition: background-color 200ms;

    &:hover {
        background-color: var(--inline-filters-bg-secondary);
    }

    &[data-view-only='true'] {
        pointer-events: none;

        [data-section-remove],
        [data-section-popover-arrow] {
            display: none;
        }
    }

    order: 999;

    color: var(--mantine-color-dimmed);

    &[data-active='true'] {
        order: 0;

        outline: 1px solid var(--inline-filters-active-border-color);

        color: inherit;
    }

    cursor: pointer;

    mapbox-geocoder {
        [class$='Geocoder'] {
            background-color: transparent;
        }

        input {
            padding: 0;
            height: 20px;

            padding-left: 27px;
            margin-left: -27px;

            width: calc(100% + 27px);
        }

        [class$='SearchIcon'],
        [class$='ActionIcon'] {
            display: none !important;
        }
    }

    mapbox-search-listbox {
        [class$='Results'] {
            font-size: var(--mantine-font-size-sm);
        }
    }
}

.target {
    display: flex;
    gap: var(--mantine-spacing-xs);

    padding: var(--padding-y) var(--padding-x);
    margin: calc(var(--padding-y) * -1) calc(var(--padding-x) * -1);
}
