@use "./_mantine.scss" as *;
.root,
.placeholder {
    display: flex;
    flex-direction: column;
    gap: 4px;

    width: auto;
    height: auto;
}

.placeholder {
    padding: 3px 4px;

    font-size: 12px;
    white-space: nowrap;
    text-align: center;
    line-height: 1;

    [data-size='xs'] & {
        font-size: 10px;
    }

    [data-size='md'] & {
        padding: 4px 8px;

        font-size: 15px;
    }

    [data-inline='true'] & {
        flex-direction: row;

        .prefix {
            font-size: inherit;
            opacity: 0.7;
        }
    }
}

.prefix {
    display: block;

    font-size: 9px;

    opacity: 0.9;
}
