@use "./_mantine.scss" as *;
.root {
    --inline-filters-padding-x: 10px;
    --inline-filters-padding-y: 7px;

    --inline-filters-bg-primary: var(--mantine-color-gray-1);
    --inline-filters-bg-secondary: var(--mantine-color-gray-2);
    --inline-filters-color-hover: var(--mantine-color-gray-9);
    --inline-filters-color-placeholder: var(--mantine-color-gray-5);
    --inline-filters-border-color: var(--mantine-color-gray-4);
    --inline-filters-active-border-color: var(--mantine-color-primary-5);
    --inline-filters-DC-color: var(--mantine-color-DC-5);
    --inline-filters-AC-color: var(--mantine-color-AC-5);

    text-align: left;

    &[data-variant='dark'] {
        --inline-filters-bg-primary: var(--mantine-color-gray-9);
        --inline-filters-bg-secondary: var(--mantine-color-gray-8);
        --inline-filters-color-hover: white;
        --inline-filters-color-placeholder: var(--mantine-color-gray-6);
        --inline-filters-border-color: var(--mantine-color-gray-7);
        --inline-filters-DC-color: var(--mantine-color-blue-5);
        --inline-filters-AC-color: var(--mantine-color-red-5);
    }

    &[data-size='md'] {
        --inline-filters-padding-x: 8px;
        --inline-filters-padding-y: 5px;

        font-size: var(--mantine-font-size-sm);
    }

    &[data-size='sm'] {
        --inline-filters-padding-x: 6px;
        --inline-filters-padding-y: 4px;

        font-size: var(--mantine-font-size-xs);
    }
}
