@use "./_mantine.scss" as *;
.search {
    position: sticky;
    top: 0;

    margin: 4px;
    margin-bottom: 8px;

    min-width: 240px;

    padding-top: 4px;
    margin-top: -4px;

    padding-left: 8px;
    margin-left: -4px;

    padding-right: 8px;
    margin-right: -4px;

    background: white;
}
