@use "./_mantine.scss" as *;
.root {
    cursor: text;

    color: var(--inline-filters-color-placeholder);

    text-decoration: underline solid var(--inline-filters-border-color);
    text-underline-offset: 2px;

    &:hover {
        text-decoration-color: transparent;
    }
}
