@use "./_mantine.scss" as *;
.root {
    padding: var(--mantine-spacing-xl);
    border: 2px solid;
    border-color: var(--mantine-color-gray-2);
    border-style: dashed;
    border-radius: var(--mantine-radius-sm);

    color: var(--mantine-color-gray-5);
    text-align: center;

    flex-direction: column;
    gap: var(--mantine-spacing-xs);
}
