@use "./_mantine.scss" as *;
.datasheet {
    position: relative;
    flex: 1;

    max-height: 300px;

    overflow-y: hidden;
}

.moreButton {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
