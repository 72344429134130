@use "./_mantine.scss" as *;
.clearButton {
    background-color: var(--inline-filters-bg-secondary);
}

.clearButtonWrapper {
    position: sticky;
    right: 0;

    margin-left: auto;
}

.clearGradient {
    --width: 40px;
    --offset: 20px;

    display: block;

    margin-right: calc(var(--offset) * -1);

    width: calc(var(--offset) + var(--width));

    background-image: linear-gradient(-90deg, rgba(248, 249, 250, 1), rgba(248, 249, 250, 0));

    [data-variant='dark'] {
        background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}
