@use "./_mantine.scss" as *;
.voltageTypeSwitch {
    display: flex;
    align-items: center;

    gap: 4px;

    &[data-theme='light'] {
        --button-background: var(--mantine-color-gray-1);
        --button-color: var(--mantine-color-gray-7);
    }

    &[data-theme='dark'] {
        --button-background: var(--mantine-color-gray-8);
        --button-color: #ffffff;
    }
}

.voltageTypeButton {
    background-color: var(--button-background);
    color: var(--button-color);

    font-size: var(--mantine-font-size-xs);
    font-weight: 700;
    // Ensure that text is properly centered
    line-height: 1rem;

    width: 30px;
    height: 20px;

    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
        transform: none;
    }

    &:disabled {
        cursor: default;
    }

    &[data-variant='outline'] {
        background-color: transparent;
        border: 1px solid var(--mantine-color-gray-3);

        &[data-voltage-type='AC'] {
            color: var(--mantine-color-AC-4);
        }

        &[data-voltage-type='DC'] {
            color: var(--mantine-color-DC-4);
        }
    }

    &[data-active='true'] {
        &[data-variant='filled'] {
            &[data-voltage-type='AC'] {
                background: var(--mantine-color-AC-4);
                color: #ffffff;
            }

            &[data-voltage-type='DC'] {
                background: var(--mantine-color-DC-4);
                color: #ffffff;
            }
        }

        &[data-variant='outline'] {
            &[data-voltage-type='AC'] {
                border: 1px solid var(--mantine-color-AC-4);
            }

            &[data-voltage-type='DC'] {
                border: 1px solid var(--mantine-color-DC-4);
            }
        }
    }
}

.ampersand {
    font-size: var(--mantine-font-size-xs);
    // font-weight: 700;
    line-height: 1rem;
}
