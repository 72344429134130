@use "./_mantine.scss" as *;
.root {
    * {
        font-size: var(--mantine-font-size-md);
    }

    ul {
        margin: var(--mantine-spacing-xs);
        padding-left: var(--mantine-spacing-sm);
    }

    a {
        color: var(--mantine-color-brand-5);
    }
}
