@use "./_mantine.scss" as *;
.root {
    --spacing: var(--mantine-spacing-md);

    gap: var(--mantine-spacing-sm);

    &[data-orientation='horizontal'] {
        @media (min-width: $mantine-breakpoint-sm) {
            display: flex;
            flex-direction: row;
            // align-items: center;
            justify-content: space-between;

            .image {
                aspect-ratio: auto;
            }

            .imageWrapper {
                margin-right: 0;
                margin-bottom: calc(var(--spacing) * -1);

                width: 60%;
            }

            .content {
                width: 40%;
            }
        }
    }
}

.content {
    height: 100%;
}

.imageWrapper {
    position: relative;

    margin-top: calc(var(--spacing) * -1);
    margin-right: calc(var(--spacing) * -1);
    margin-left: calc(var(--spacing) * -1);

    &::after {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 1;

        background-color: rgba(0, 0, 0, 0.05);
    }
}

.imagePreview {
    aspect-ratio: 600 / 340;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--mantine-color-gray-5);
}

.actions {
    position: absolute;
    top: var(--mantine-spacing-xs);
    right: var(--mantine-spacing-xs);

    z-index: 1;
}
