@use "./_mantine.scss" as *;
.root {
    :global .mantine-Grid-root {
        --grid-gutter: 0;
    }

    :global .mantine-Grid-col {
        --col-flex-basis: auto;
        --col-max-width: auto;

        &:first-of-type {
            :global .mantine-Input-input {
                padding-left: 0;
            }
        }

        &:not(:last-of-type) {
            border-right: 1px solid var(--inline-filters-border-color);

            :global .mantine-Input-input {
                max-width: 40px;
                padding-right: 6px;
            }

            :global .mantine-Input-section {
                display: none;
            }
        }
    }

    :global .mantine-Input-input {
        --_input-padding-right: 24px;

        min-width: 60px;
        max-width: 80px;

        height: auto;
        min-height: auto;

        font-size: inherit;
        line-height: 1.5;

        background: transparent;
        color: var(--inline-toolbar-color);

        border: none;

        &::placeholder {
            color: var(--inline-filters-color-placeholder);
        }
    }

    :global .mantine-Input-section {
        --_section-size: 24px;

        pointer-events: none;

        font-size: inherit;

        color: var(--inline-filters-color-placeholder);
    }

    :global .mantine-Combobox-options {
        color: var(--mantine-color-gray-9);
    }
}

.hidden {
    pointer-events: none;
    opacity: 0;
    position: absolute;
}

.dynamicWidth {
    position: relative;

    .hidden {
        position: relative;
    }

    .input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;

        > * {
            flex: 1;
        }

        :global .mantine-Input-input {
            width: 100%;
            height: 100%;
            max-width: none;
            padding: 0;

            font-weight: 400;
        }
    }
}
