@use "./_mantine.scss" as *;
.navigation {
    display: grid;
    // grid-template-columns: 1fr auto 1fr;

    grid-gap: var(--mantine-spacing-xs);
    grid-template-areas:
        'navigationBack navigationSorting'
        'navigationTabs navigationTabs';

    width: 100%;

    @media (min-width: 768px) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 'navigationBack navigationTabs navigationSorting';
    }
}

.navigationBack {
    grid-area: navigationBack;
}

.navigationTabs {
    grid-area: navigationTabs;
}

.navigationSorting {
    grid-area: navigationSorting;

    display: flex;
    justify-content: flex-end;
}

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    overflow: hidden;

    border-radius: var(--mantine-radius-sm);
}

.tab {
    text-align: center;
    text-decoration: none;

    background-color: var(--inline-filters-bg-primary);
    color: currentColor;

    padding: var(--inline-filters-padding-y) var(--inline-filters-padding-x);

    &[data-active='true'] {
        font-weight: 500;

        background-color: var(--mantine-color-gray-2);
    }
}

.tab + .tab {
    border-left: 1px solid var(--mantine-color-gray-2);
}

.filters {
    :global {
        .mantine-Group-root {
            flex-wrap: nowrap;
        }

        .mantine-Text-root,
        .mantine-UnstyledButton-root {
            color: white;
            white-space: nowrap;
        }
    }
}
