@use "./_mantine.scss" as *;
.root {
    display: flex;
    align-items: center;
    justify-content: center;

    --color: var(--mantine-color-gray-6);
    --color-hover: var(--inline-filters-color-hover);
    --radius: 1px;

    color: var(--color);

    &:hover {
        color: var(--color-hover);
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    &[data-variant*='outline'] {
        border-radius: var(--radius);
        border: 1px solid var(--color);

        &:hover {
            border-color: var(--color-hover);
        }
    }

    transition: all 100ms ease;
}
