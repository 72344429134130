@use "./_mantine.scss" as *;
.root {
    [data-component-icon-label-text] {
        display: none;
    }

    [data-component-icon-label-icon] {
        visibility: visible !important;
    }
}
