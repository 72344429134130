@use "./_mantine.scss" as *;
.indicator {
    animation: rainbow 10s infinite;
}

@keyframes rainbow {
    0% {
        color: var(--mantine-color-blue-7);
    }
    14% {
        color: var(--mantine-color-cyan-7);
    }
    28% {
        color: var(--mantine-color-teal-7);
    }
    42% {
        color: var(--mantine-color-green-7);
    }
    57% {
        color: var(--mantine-color-lime-7);
    }
    71% {
        color: var(--mantine-color-yellow-7);
    }
    85% {
        color: var(--mantine-color-orange-7);
    }
    100% {
        color: var(--mantine-color-red-7);
    }
}
