@use "./_mantine.scss" as *;
.root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 201;

    transition: left 200ms ease-in-out;

    display: flex;
    justify-content: space-between;
    gap: 0;

    &[data-sidebar-open='true'] {
        left: var(--sidebar-nav-width);
    }

    [data-search-submit] {
        display: none;
    }

    @media (max-width: $mantine-breakpoint-sm) {
        width: 100% !important;
    }
}

.inner {
    z-index: 1;

    padding: var(--mantine-spacing-xs);

    background-color: var(--mantine-color-gray-9);
}

.filters {
    padding: 0 var(--mantine-spacing-xs) var(--mantine-spacing-xs);

    background-color: var(--mantine-color-gray-9);

    :global {
        [data-inline-filter-section] {
            color: white;
            border: 1px solid var(--mantine-color-gray-8);
        }

        [data-clear-gradient] {
            background-image: linear-gradient(-90deg, var(--mantine-color-gray-9), rgba(0, 0, 0, 0));

            + [data-inline-filter-section] {
                background-color: var(--mantine-color-gray-8);
            }
        }

        .mantine-Group-root {
            flex-wrap: nowrap;
        }

        .mantine-Text-root,
        .mantine-UnstyledButton-root {
            color: white;
            white-space: nowrap;
        }
    }
}
