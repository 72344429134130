@use "./_mantine.scss" as *;
.root {
    :global [data-size='lg'] .mantine-Input-input,
    :global [data-size='lg'] .mantine-Input-section {
        --input-size: 48px;
        --input-font-size: max(16px, var(--mantine-font-size-md));
    }
}

.input {
    //increasing specificity
    &.input {
        border-radius: var(--mantine-radius-sm);

        padding-right: 120px !important; // search button
    }
}

.reset {
    display: flex;

    &:hover {
        color: var(--mantine-color-gray-8);
    }
}

.section {
    padding-right: calc(5 * 0.0625rem * 1);

    height: var(--input-size);

    top: 0;
}
