@use "./_mantine.scss" as *;
.cell {
    position: absolute;
    top: 0;
    left: 0;

    width: var(--cell-width);
    height: var(--cell-height);
}

.componentIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;

    border: 2px solid var(--mantine-color-gray-5);
    border-radius: var(--mantine-radius-sm);
}

.componentWrapper {
    @extend .componentIcon;

    position: absolute;

    outline: 2px solid transparent;
    outline-offset: 4px;

    cursor: pointer;
    pointer-events: none;

    transition:
        background-color 0.15s ease,
        box-shadow 0.15s ease,
        outline 0.3s ease;

    &:hover,
    [data-dropzone-active='true'] & {
        background-color: var(--mantine-color-gray-1);
    }

    &[data-editable='true'] {
        pointer-events: all;
    }

    &[data-selectable-preview='true'],
    &[aria-selected='true'] {
        border-color: var(--mantine-color-primary-6);
    }

    &[aria-pressed='true'] {
        cursor: move;
    }

    &[data-multiple='true']:not([aria-selected='true'])::before {
        content: '';

        position: absolute;
        left: 5px;
        top: 5px;

        z-index: -1;

        width: 100%;
        height: 100%;

        outline: 2px solid var(--mantine-color-gray-3);
        border-radius: var(--mantine-radius-xs);
    }

    &[data-multiple='true']:not([aria-selected='true']) {
        // box-shadow: 4px 4px 0 var(--mantine-color-gray-4);
    }

    &[data-highlighted='notice'] {
        @keyframes pulseNotice {
            0% {
                outline-color: transparent;
            }
            50% {
                outline-color: var(--mantine-color-blue-7);
            }
        }

        animation: pulseNotice 1s ease-in-out alternate;
        animation-iteration-count: 5;
    }

    &[data-highlighted='error'] {
        @keyframes pulseError {
            0% {
                outline-color: var(--mantine-color-red-4);
            }
            50% {
                outline-color: var(--mantine-color-red-8);
            }
        }

        animation: pulseError 1s ease-in-out alternate;
        animation-iteration-count: 5;
    }
}

.componentShadow {
    position: absolute;

    left: -2px;
    top: -2px;

    width: var(--icon-width);
    height: var(--icon-height);
}

.componentResizeShadow {
    position: absolute;
    z-index: -1;

    background: var(--mantine-color-gray-2);
    border: 1.5px solid var(--mantine-color-gray-3);
    border-radius: var(--mantine-radius-sm);

    .componentWrapper[data-anchor*='n'] & {
        top: 0;
    }
    .componentWrapper[data-anchor*='e'] & {
        right: 0;
    }
    .componentWrapper[data-anchor*='s'] & {
        bottom: 0;
    }
    .componentWrapper[data-anchor*='w'] & {
        left: 0;
    }
}
