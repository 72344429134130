@use "./_mantine.scss" as *;
.underline {
    text-decoration: underline solid var(--inline-filters-border-color);
    text-underline-offset: 2;
}

.value {
    @extend .underline;

    font-size: inherit;
    font-weight: 500;
}

.placeholder {
    @extend .underline;

    font-size: inherit;
    color: var(--inline-filters-color-placeholder);
}
